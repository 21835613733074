import React from 'react';
import Layout from 'components/layout';
import Head from 'components/head';
import styled from 'styled-components';
import { Flex, Button } from '@chakra-ui/react';
import { EmailIcon, PhoneIcon } from '@chakra-ui/icons';
import logo from '../../static/logoVisualmaCurvas.svg';
import { OutboundLink } from 'gatsby-plugin-google-gtag';

const Contacto = () => (
  <Layout>
    <Head pageTitle="Contacto - Mapping 3D, Realidad Virtual y Animación en México" />
    <Content>
      <Flex
        textAlign="center"
        color="white"
        p="0"
        flexDirection="column"
        alignItems="center"
        marginTop="12vh"
      >
        <img src={logo} alt="Visualma Mexico" style={{maxWidth: '280px'}}/>
        <div
          style={{
            maxWidth: '90%',
            width: '980px',
            margin: '3em auto',
            color: '#222',
            fontSize: 'calc(1.4em + 1vw)',
            lineHeight: '1.2',
          }}
        >
          ¡Hagamos realidad tu proyecto!

        </div>
        <Flex justifyContent="center" flexDirection="column">
          <OutboundLink
            href="tel:3338253793"
            style={{
              color: '#1c9bc1',
              fontSize: '20px',
              textDecoration: 'none',
              fontWeight: 'bold',
            }}
          >
            <PhoneIcon mr="8px" /> 33 3825-3793
          </OutboundLink>
          <Button
            as={OutboundLink}
            href="mailto:contacto@visualma.com"
            target="_blank"
            colorScheme="azulin"
            p="1em 5em"
            maxWidth="280px"
            m="0 auto 6em auto"
            size="lg"
            style={{ marginTop: '18px' }}
            leftIcon={<EmailIcon />}
          >
            contacto@visualma.com
          </Button>
        </Flex>
        <h1>Contacto visualma</h1>
      </Flex>

    </Content>
  </Layout>
);
Contacto.propTypes = {
  // data: PropTypes.object.isRequired,
};
const Content = styled.div``;

export default Contacto;
